
<template>
  <div
    id="forgotm"
    style="min-height: calc(100vh - 20px)"
    class="forgotm-page"
    tag="section"
  >
    <b-row>
      <div class="forgotm-box" no-body>
        <div class="forgotm-wrapper">
          <img src="@/assets/images/venue_booking/logo.svg" id="logo_image_f" />
          <b-form id="forgotm-input-group-1" @submit.prevent="handleSubmit">
            <h2>Forgot Password</h2>
            <h6>Password reset link has been send successfully. Check your Inbox</h6>
            <br>
            
            <!-- <div class="mt-2">Didn't  recived mail? &nbsp;<b-link to="#" class="link">Resend link</b-link>
            </div> -->

            <div class="mt-2">Back to&nbsp;<b-link to="/login" class="link">Login?</b-link>
            </div>
             <!--
            <div class="mt-3">
              <label>Not Registered?</label> &nbsp;
              <b-link to="/register" class="link">
                <u>Create an Account</u>
              </b-link>
            </div> -->
          </b-form>
        </div>
      </div>
    </b-row>
  </div>
</template>
    

<script>

export default {
  name: "Boxedforgotm",
  
  data: () => ({
    
  }),
  computed: {},
  validations: {

  },
  methods: {
  
  },
};
</script>
<style scoped>
.forgotm-page {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#logo_image_f {
  width: 180px;
  /* margin-left: 90px; */
  /* height: 60px; */
  padding-bottom: 15px;
}
.forgotm-wrapper {
  text-align: center;
}
.forgotm-box {
  background: #fff;
  max-width: 400px;
  width: 100%;
  padding: 30px;
  margin: 8% auto;
}
@media screen and (max-width: 475px) {
  .forgotm-box {
    max-width: 100%;
    margin: 0 30px;
  }
}
</style>